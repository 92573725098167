import React from "react"
import styled from 'styled-components';
import Layout from "../layouts";
import Hover from "../components/hover";
import Footer from "../components/footer";
import VideoMockup from "../components/videoMockup";
import Container from "../components/./container";
import AnimatedText from '../components/animatedText';
import ArrowDown from "../components/arrowDown";
import Seo from "../components/seo"

const Wrapper = styled.div`
.flux > div {
    padding-top: 100px;
}

.flux > div:first-child {
    padding-top: 20vh;
    padding-bottom: 5vh;
}

.flux > div:nth-child(2){
    padding-top: 0;
}

h1{
    text-align:left;
    text-transform: uppercase;
    letter-spacing: .5rem;
    font-size: 5rem;
    padding-bottom:2rem;
}

.wecreate2,
.wecreate3{
    position:absolute;
    top:0;
}

@media(max-width:512px){
    h1{
    letter-spacing: .3rem;
    font-size: 10vw;
}
}
`


// markup
const DigitalEvents = () => {


    return (
        <>
            <Layout>
                <Seo title="Digital Events - Vertual" />
                <Hover type="">
                    <main data-scroll data-scroll-id="update">
                        <ArrowDown />
                        <Wrapper>
                            <div className="flux">
                                <Container block position="intro" reversed direction="column">
                                    <AnimatedText tag="h1">Digital Events</AnimatedText>
                                    <AnimatedText tag="p">
                                        We transform a website into a digital event platform making a memorable attendee experience by integrating
                                        manifestation of actual reality, but one based in a virtual world, optimized for every device, every language, and
                                        timezone.
                                    </AnimatedText>
                                </Container>
                                <VideoMockup video={"digitalevents1"} fullpage />
                                <Container vh>
                                    <div className="mockupVideo">
                                        <VideoMockup video={"iphonedigitalevents"} flexend />
                                    </div>
                                    <div className="description">
                                        <AnimatedText tag="h2">Unforgettable Virtual Events</AnimatedText>
                                        <AnimatedText tag="p">The platform provides an innovative solution for your stunning Virtual Conferences, special collaborations, trade shows
                                            and live events, enabling your digital community to be immersed in highly interactive and lifelike engaging experiences
                                            through virtual environments.</AnimatedText>
                                    </div>
                                </Container>
                                <VideoMockup video={"ipaddigitalevents"} />
                                <Container reversed vh>
                                    <div className="description">
                                        <AnimatedText tag="h2">Immersive Audio Experiences</AnimatedText>
                                        <AnimatedText tag="p">With our object-based spatial audio technology we provide a full 360-degree immersive audio experience, that can
                                            be experienced using a normal pair of speakers and headphones.</AnimatedText>
                                    </div>
                                    <div className="mockupVideo">
                                        <VideoMockup video={"iphonedigitalevents2"} flexend />
                                    </div>
                                </Container>
                                <VideoMockup video={"digitalevents2"} />
                                <Footer page="virtualfashion" />
                            </div>
                        </Wrapper>
                    </main>
                </Hover>
            </Layout>
        </>
    )
}

export default DigitalEvents


